<script setup>
const { t, locale } = useI18n()

const cartStore = useShoppingCartStore()

const authStore = useAuthStore()
const route = useRoute()

const configuratorLink = computed(() => {
  return `https://tool.giardino-online.com?language=${locale.value}`
})
</script>

<template>
  <div class="border-b bg-white">
    <div class="container mx-auto z-200 relative hidden w-screen transform items-center justify-between gap-5 bg-white px-2 pt-6 pb-4 lg:flex">
      <div class="flex justify-center">
        <NuxtLinkLocale class="cursor-pointer" to="/">
          <img src="~/assets/images/giardino-logo.svg" alt="Giardino logo">
        </NuxtLinkLocale>
      </div>

      <div class="icons flex flex-1 items-center justify-between gap-5">
        <ClientOnlyNavigationSearchBar />

        <div class="flex-center flex flex-none gap-5">
          <a href="https://issuu.com/giardino-gardentrade/stacks/810d33d17dfc4fb39b54cd5f7ef67f1c" target="_blank">
            <PrimeButton class="gap-2">
              <span>{{ t('footer.catalog') }}</span>
              <span class="i-mdi-open-in-new text-white" />
            </PrimeButton>
          </a>

          <NuxtLinkLocale
            v-tooltip.bottom="{ value: t('b2b_add.title'), class: 'mt-3', showDelay: 250 }"
            class="relative z-10"
            to="/quick-add"
          >
            <img src="~/assets/images/navbar/bulk.svg" alt="Bulk">
          </NuxtLinkLocale>

          <NuxtLinkLocale
            v-tooltip.bottom="{ value: t('account.my_account'), class: 'mt-3', showDelay: 250 }"
            :to="authStore.isAuthenticated ? '/account' : '/login'"
          >
            <img src="~/assets/images/navbar/account.svg" alt="Account">
          </NuxtLinkLocale>

          <NuxtLinkLocale
            v-tooltip.bottom="{ value: t('cart.cart'), class: 'mt-3', showDelay: 250 }"
            class="relative z-10 mr-4"
            :class="{ 'text-primary': route.path.includes('cart') }"
            to="/cart"
          >
            <img src="~/assets/images/navbar/cart.svg" alt="Cart">
            <div
              v-if="cartStore.shoppingCart?.items && cartStore.shoppingCart.items.length !== 0"
              class="bg-secondary flex-center absolute -bottom-4 -right-4 flex h-6 w-6 rounded-full text-sm text-white"
            >
              {{ cartStore.shoppingCart?.items?.length }}
            </div>
          </NuxtLinkLocale>
        </div>
      </div>
    </div>

    <div class="container mx-auto lg:flex flex-col hidden w-screen transform bg-white px-2 pb-4 gap-1">
      <div class="items-center justify-between gap-8 bg-white lg:flex">
        <div class="flex w-full justify-between gap-8">
          <div class="flex-center flex gap-5">
            <ProductsPopover :link="['products', 'product']" />

            <NuxtLinkLocale class="whitespace-nowrap font-bold hover:underline" active-class="text-primary" to="/dealers">
              {{ t('navbar.dealers') }}
            </NuxtLinkLocale>

            <NuxtLinkLocale class="whitespace-nowrap font-bold hover:underline" active-class="text-primary" to="/installation">
              {{ t('navbar.installers') }}
            </NuxtLinkLocale>

            <NuxtLinkLocale class="whitespace-nowrap font-bold hover:underline" active-class="text-primary" to="/advice">
              {{ t('navbar.advice') }}
            </NuxtLinkLocale>

            <a :href="configuratorLink" class="whitespace-nowrap font-semibold hover:underline" target="_blank">
              <div class="flex items-center gap-2">
                <span>{{ t('navbar.dropdown.configurator') }}</span>
                <span class="i-mdi-open-in-new" />
              </div>
            </a>
          </div>

          <div class="flex-center flex gap-5">
            <!--
          <NuxtLinkLocale class="whitespace-nowrap font-semibold" active-class="text-primary" to="/history">
            {{ t('navbar.history') }}
          </NuxtLinkLocale> -->

            <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/service">
              {{ t('navbar.customer_service') }}
            </NuxtLinkLocale>

            <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/faq">
              {{ t('navbar.faq') }}
            </NuxtLinkLocale>

            <LanguagePopover />
          </div>
        </div>
      </div>

      <div class="text-sm text-secondary flex gap-4">
        <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/team">
          {{ t('navbar.team') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/green">
          {{ t('navbar.giardino_goes_green') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/page/mission-and-vision">
          {{ t('navbar.mission_and_vision') }}
        </NuxtLinkLocale>
        <NuxtLinkLocale class="whitespace-nowrap font-semibold hover:underline" active-class="text-primary" to="/blog">
          {{ t('navbar.blog') }}
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
