<script setup>
import { useI18n } from 'vue-i18n'

const open = ref(false)
const { hook } = useNuxtApp()

const { t, locale, locales } = useI18n({})
const cartStore = useShoppingCartStore()
const authStore = useAuthStore()

function switchSidebar() {
  open.value = !open.value
}

const configuratorLink = computed(() => {
  return `https://tool.giardino-online.com?language=${locale.value}`
})

onMounted(() => {
  hook('page:finish', async () => {
    open.value = false
  })
})

const switchLocalePath = useSwitchLocalePath()
const router = useRouter()

const selectedLocale = computed({
  get: () => locale.value,
  set: async (value) => {
    await router.push(switchLocalePath(value))
  },
})
</script>

<template>
  <div class="relative w-full border-b bg-white p-4 lg:hidden">
    <div class="items-between flex w-full justify-between">
      <div class="z-200 flex cursor-pointer items-center">
        <div @click="switchSidebar()">
          <button class="z-200 relative m-4 flex items-center space-x-2 focus:outline-none">
            <div class="flex-center flex w-6">
              <span
                class="h-2px absolute w-full transform bg-current transition"
                :class="[open ? 'translate-y-0 rotate-45' : '-translate-y-2']"
              />

              <span
                class="h-2px absolute w-full transform bg-current transition"
                :class="[open ? 'translate-x-3 opacity-0' : 'opacity-100']"
              />

              <span
                class="h-2px absolute w-full transform bg-current transition"
                :class="[open ? 'translate-y-0 -rotate-45' : 'translate-y-2']"
              />
            </div>
          </button>
        </div>

        <NuxtLinkLocale class="cursor-pointer" to="/">
          <img
            class="-mt-1 h-6"
            src="~/assets/images/giardino-logo.svg"
            alt="Giardino logo"
          >
        </NuxtLinkLocale>
      </div>

      <div class="icons flex items-center gap-6 mr-4">
        <NuxtLinkLocale class="relative z-250" to="/quick-add">
          <img src="~/assets/images/navbar/bulk.svg" alt="Bulk">
        </NuxtLinkLocale>
        <NuxtLinkLocale :to="authStore.isAuthenticated ? '/account' : '/login'" class="z-250">
          <img src="~/assets/images/navbar/account.svg" alt="Account">
        </NuxtLinkLocale>
        <NuxtLinkLocale
          class="z-250 relative"
          active-class="border-primary border-b-2 pb-1"
          to="/cart"
        >
          <img src="~/assets/images/navbar/cart.svg" alt="Cart">
          <div
            v-if="cartStore.shoppingCart?.items && cartStore.shoppingCart.items.length !== 0"
            class="bg-secondary flex-center absolute -bottom-4 -right-4 flex h-6 w-6 rounded-full text-sm text-white"
          >
            {{ cartStore.shoppingCart?.items?.length }}
          </div>
        </NuxtLinkLocale>
      </div>
    </div>

    <div class="mt-4">
      <ClientOnlyNavigationSearchBar />
    </div>

    <div class="relative -z-10">
      <transition name="fade">
        <div
          v-if="open"
          class="fixed top-0 left-0 -z-10 h-full w-full overflow-hidden bg-black bg-opacity-20"
          @click="switchSidebar()"
        />
      </transition>
    </div>

    <div
      class="z-195 overflow-none absolute inset-0 bg-white transition-all"
      :class="[open ? 'max-h-full' : 'max-h-0 overflow-hidden']"
    >
      <div class="flex flex-col items-ceter divide-y overflow-scroll bg-white px-8 pt-36 pb-8">
        <div class="menu-link">
          <ProductsPopover :link="['products', 'product']" />
        </div>

        <NuxtLinkLocale to="/dealers" class="menu-link">
          {{ t('navbar.dealers') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale class="menu-link" to="/installation">
          {{ t('navbar.installers') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/advice" class="menu-link">
          {{ t('navbar.advice') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/blog" class="menu-link">
          {{ t('navbar.blog') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/team" class="menu-link">
          {{ t('navbar.team') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/green" class="menu-link">
          {{ t('navbar.giardino_goes_green') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/page/mission-and-vision" class="menu-link">
          {{ t('navbar.mission_and_vision') }}
        </NuxtLinkLocale>

        <!--
          <NuxtLinkLocale to="/history" class="menu-link">
            {{ t('navbar.history') }}
          </NuxtLinkLocale> -->

        <NuxtLinkLocale to="/service" class="menu-link">
          {{ t('navbar.customer_service') }}
        </NuxtLinkLocale>

        <NuxtLinkLocale to="/faq" class="menu-link">
          {{ t('navbar.faq') }}
        </NuxtLinkLocale>

        <a :href="configuratorLink" class="menu-link" target="_blank">
          <div class="flex items-center gap-2">
            <span>{{ t('navbar.dropdown.configurator') }}</span>
            <span class="i-mdi-open-in-new" />
          </div>
        </a>
        <div class="menu-link flex gap-2 justify-between">
          <a href="https://issuu.com/giardino-gardentrade/stacks/810d33d17dfc4fb39b54cd5f7ef67f1c" target="_blank" class="">
            <PrimeButton class="gap-2">
              <span>{{ t('footer.catalog') }}</span>
              <span class="i-mdi-open-in-new text-white" />
            </PrimeButton>
          </a>
          <div>
            <PrimeDropdown
              v-model="selectedLocale"
              :options="locales"
              :option-label="locale => locale.code.toUpperCase()"
              :option-value="locale => locale.code"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.menu-link {
  @apply whitespace-nowrap text-base font-semibold py-4;
  &.router-link-active {
    @apply text-primary;
  }
}
</style>
